/**
 * Component to execute various initialization tasks that need to find place at the startup of the application
 */
import { useEffect, useRef } from 'react';
import * as SentryBrowser from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { buildFingerprint, muiXLicenseKey } from 'utils/env';

// i18n lib needs to be bundled
import './i18n';
import { isProductionBranch, isDevBuild, sentryDsn } from './utils/env';
import { useCurrentUser } from 'providers/CurrentUserProvider';

import { LicenseInfo } from '@mui/x-license-pro';
import { useApiVersionInfo } from 'utils/hooks/useApiVersionInfo';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import useGoogleAnalytics from 'utils/hooks/useGoogleAnalytics';

LicenseInfo.setLicenseKey(muiXLicenseKey);

export const Initializer = () => {
  const isSentryInitialized = useRef(false);

  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const anonymize = !currentUser?.latestPrivacyPolicyAccepted;
  useGoogleAnalytics(currentUser, anonymize);

  // Set user details in the sentry scope when login state changes'
  useEffect(() => {
    // Avoid reinitializing
    if (!currentUser || isSentryInitialized.current) {
      return;
    }

    // Do not use replay logging for cypress test sessions
    const isTestUser = currentUser.userName?.includes('cypress');
    const anonymize = !currentUser.latestPrivacyPolicyAccepted;

    if (sentryDsn !== '' && !isDevBuild) {
      Sentry.init({
        dsn: sentryDsn,
        integrations: isTestUser
          ? []
          : [
              Sentry.replayIntegration({
                maskAllText: anonymize,
                blockAllMedia: false,
                beforeErrorSampling: (event) => {
                  // Certain errors are not replayed, to avoid leaking sensitive data or to avoid using up the quota
                  const skipReplayForErrors = ['Unauthorized access in query'];
                  const error = event.exception?.values?.[0];
                  if (
                    error &&
                    skipReplayForErrors.find((skipError) => error.value?.includes(skipError))
                  ) {
                    return false;
                  }
                  return true;
                },
              }),
            ],
        tracesSampleRate: 1.0,
        release: buildFingerprint,
        replaysSessionSampleRate: 0, // Disable session replay for now
        replaysOnErrorSampleRate: 1,
        environment: isProductionBranch ? 'production' : 'development',
      });

      SentryBrowser.getCurrentScope().setUser({
        id:
          !currentUser.userName || anonymize // Anonymize user if the latest privacy policy is not accepted
            ? 'Anonymous'
            : currentUser.userName,
        email:
          !currentUser.email || anonymize // Anonymize user if the latest privacy policy is not accepted
            ? 'Anonymous'
            : currentUser.email,
      });
    }

    isSentryInitialized.current = true;
  }, [currentUser]);

  const apiVersion = useApiVersionInfo();

  useEffect(() => {
    if (apiVersion && apiVersion.fingerprint !== buildFingerprint && isProductionBranch) {
      enqueueSnackbar(t('WARNING.APP_VERSION_OUTDATED'), {
        variant: 'error',
        autoHideDuration: 10000,
      });
    }
  }, [apiVersion, enqueueSnackbar, t]);

  return null;
};
